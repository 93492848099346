import React, { useState } from 'react';
import {
  HomeIcon,
  UserIcon,
  CogIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
  DocumentChartBarIcon,
  CreditCardIcon,
  ChartBarIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from './context/AuthContext';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState({
    clientes: false,
    pagos: false,
    gastos: false,
    reportes: false,
    configuracion: false,
  });

  const { logout } = useAuth();

  const toggleSubmenu = (menu) => {
    setSubmenuOpen({
      ...submenuOpen,
      [menu]: !submenuOpen[menu],
    });
  };

  return (
    <>
      {/* Botón de menú para pantallas pequeñas */}
      <button
        className={`lg:hidden p-4 text-white bg-blue-500 fixed top-4 left-4 z-50 rounded-full shadow-lg ${
          isOpen ? 'hidden' : 'block'
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bars3Icon className="h-6 w-6" />
      </button>
      

      {/* Sidebar */}
      <div
        className={`h-screen w-64 bg-gray-800 text-white transform lg:transform-none transition-transform duration-300 ease-in-out fixed lg:relative z-40 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Encabezado del menú */}
        <div className="flex justify-between items-center p-4">
          <h2 className="text-2xl font-bold">Dashboard</h2>
          <button
            className="text-white lg:hidden"
            onClick={() => setIsOpen(false)}
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <div className="overflow-y-auto h-[calc(100vh-4rem)]">
          <ul className="space-y-4 px-4">
            {/* Inicio */}
            <li className="hover:bg-gray-700 p-2 rounded flex items-center">
              <HomeIcon className="h-6 w-6 mr-2" />
              <a href="/dashboard" className="block text-white">
                Inicio
              </a>
            </li>

            {/* Clientes */}
            <li
              className="hover:bg-gray-700 p-2 rounded flex items-center justify-between cursor-pointer"
              onClick={() => toggleSubmenu('clientes')}
            >
              <div className="flex items-center">
                <UserIcon className="h-6 w-6 mr-2" />
                <span className="block text-white">Clientes</span>
              </div>
              <span>{submenuOpen.clientes ? '▼' : '▶'}</span>
            </li>
            {submenuOpen.clientes && (
              <ul className="pl-10 space-y-2">
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/clientes/lista" className="block text-white">
                    Lista de Clientes
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/clientes/registrar" className="block text-white">
                    Registrar Nuevo Cliente
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/clientes/corriente" className="block text-white">
                    Clientes al Corriente
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/clientes/mora" className="block text-white">
                    Clientes en Mora
                  </a>
                </li>
              </ul>
            )}

            {/* Pagos */}
            <li
              className="hover:bg-gray-700 p-2 rounded flex items-center justify-between cursor-pointer"
              onClick={() => toggleSubmenu('pagos')}
            >
              <div className="flex items-center">
                <CreditCardIcon className="h-6 w-6 mr-2" />
                <span className="block text-white">Pagos</span>
              </div>
              <span>{submenuOpen.pagos ? '▼' : '▶'}</span>
            </li>
            {submenuOpen.pagos && (
              <ul className="pl-10 space-y-2">
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/pagos/registrar" className="block text-white">
                    Registrar Pago
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/pagos/historial" className="block text-white">
                    Ver Historial de Pagos
                  </a>
                </li>
              </ul>
            )}

            {/* Gastos */}
            <li
              className="hover:bg-gray-700 p-2 rounded flex items-center justify-between cursor-pointer"
              onClick={() => toggleSubmenu('gastos')}
            >
              <div className="flex items-center">
                <ChartBarIcon className="h-6 w-6 mr-2" />
                <span className="block text-white">Gastos</span>
              </div>
              <span>{submenuOpen.gastos ? '▼' : '▶'}</span>
            </li>
            {submenuOpen.gastos && (
              <ul className="pl-10 space-y-2">
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/gastos/registrar" className="block text-white">
                    Registrar Nuevo Gasto
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/gastos/historial" className="block text-white">
                    Historial de Gastos
                  </a>
                </li>
              </ul>
            )}

            {/* Reportes */}
            <li
              className="hover:bg-gray-700 p-2 rounded flex items-center justify-between cursor-pointer"
              onClick={() => toggleSubmenu('reportes')}
            >
              <div className="flex items-center">
                <DocumentChartBarIcon className="h-6 w-6 mr-2" />
                <span className="block text-white">Reportes</span>
              </div>
              <span>{submenuOpen.reportes ? '▼' : '▶'}</span>
            </li>
            {submenuOpen.reportes && (
              <ul className="pl-10 space-y-2">
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/reportes/ingresos-y-gastos" className="block text-white">
                    Balance General
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/reporte/mensual" className="block text-white">
                    Reporte Mensual
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/gastos/graficas" className="block text-white">
                    Gráficas de Gastos
                  </a>
                </li>
              </ul>
            )}

            {/* Configuración */}
            <li
              className="hover:bg-gray-700 p-2 rounded flex items-center justify-between cursor-pointer"
              onClick={() => toggleSubmenu('configuracion')}
            >
              <div className="flex items-center">
                <CogIcon className="h-6 w-6 mr-2" />
                <span className="block text-white">Configuración</span>
              </div>
              <span>{submenuOpen.configuracion ? '▼' : '▶'}</span>
            </li>
            {submenuOpen.configuracion && (
              <ul className="pl-10 space-y-2">
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/configuracion/usuarios" className="block text-white">
                    Gestión de Usuarios del Sistema
                  </a>
                </li>
                <li className="hover:bg-gray-600 p-2 rounded">
                  <a href="/configuracion/logo" className="block text-white">
                    Cambiar el Logo
                  </a>
                </li>
              </ul>
            )}

            {/* Soporte */}
            <li className="hover:bg-gray-700 p-2 rounded flex items-center">
              <UserIcon className="h-6 w-6 mr-2" />
              <a href="/soporte/manual" className="block text-white">
                Manual de Usuario
              </a>
            </li>

           {/* Cerrar Sesión */}
<li className="hover:bg-gray-700 p-2 rounded flex items-center">
  <ArrowLeftOnRectangleIcon className="h-6 w-6 mr-2" />
  <button
    onClick={() => {
      logout();
      window.location.href = "/login/personal"; // Redirige a la página de inicio de sesión
    }}
    className="block text-white"
  >
    Cerrar Sesión
  </button>
</li>

          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
