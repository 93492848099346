// src/componentes/auth/LoginUsuario.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Ajuste de la ruta de importación
import apiURL from "../../api/api";


function LoginUsuario() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth(); // Obtiene la función de login desde el contexto

  const [numeroCliente, setNumeroCliente] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [logoUrl, setLogoUrl] = useState(''); // Estado para el logo activo

  // Obtener el logo activo desde el servidor
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/logos/active`);
        setLogoUrl(response.data.path); // Guardar la URL del logo activo
      } catch (error) {
      
      }
    };
    fetchLogo();
  }, []);

  // Cambio de tipo de usuario
  const handleUserSwitch = (type) => {
    if (type === 'usuarios') {
      navigate('/login/usuarios'); // Redirigir a login de Usuarios
    } else if (type === 'personal') {
      navigate('/login/personal'); // Redirigir a login de Personal
    }
  };

  // Manejo del envío de formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!numeroCliente || !password) {
      setErrorMessage("Todos los campos son obligatorios");
      return;
    }

    try {
      const response = await axios.post(`${apiURL}/api/login/usuarios`, {
        numero_cliente: numeroCliente,
        password: password,
      });

      if (response.status === 200) {
        login('usuario', response.data.cliente);
        setSuccessMessage("Inicio de sesión exitoso");
        setErrorMessage('');
        navigate('/dashboard/usuario');
      }
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage(
        error.response?.status === 401
          ? "Credenciales incorrectas. Verifica tu número de cliente y contraseña."
          : "Error en el servidor. Inténtalo más tarde."
      );
    }
  };

  // Limpia mensajes después de 5 segundos
  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg overflow-hidden max-w-6xl w-full">
        
        {/* Sección con la imagen del lado izquierdo */}
        <div className="lg:w-2/5 hidden lg:flex bg-purple-500">
          <img 
            src="/img/agua-en-el-futuro.jpg" 
            alt="Agua en el Futuro"
            className="h-full w-full object-cover"
          />
        </div>

        {/* Sección del formulario */}
        <div className="w-full lg:w-3/5 p-8 lg:p-12 flex flex-col justify-center">
          <div className="text-center">
            <img 
              src={`http://localhost:8000/logos/${logoUrl}`} 
              alt="Logo" 
              className="mx-auto h-28 w-30"
            />
            <h2 className="mt-6 text-3xl font-bold text-gray-900">
              Servicio de Agua Potable
            </h2>
          </div>

          {/* Botones para cambiar entre Usuarios y Personal */}
          <div className="mt-4 flex justify-center space-x-2">
            <button 
              className={`px-4 py-2 rounded-full ${location.pathname === '/login/usuarios' ? 'bg-red-600 text-white' : 'bg-gray-300'}`}
              onClick={() => handleUserSwitch('usuarios')}
            >
              Usuarios
            </button>
            <button 
              className={`px-4 py-2 rounded-full ${location.pathname === '/login/personal' ? 'bg-red-600 text-white' : 'bg-gray-300'}`}
              onClick={() => handleUserSwitch('personal')}
            >
              Personal
            </button>
          </div>

          {/* Mostrar mensajes de error o éxito */}
          {errorMessage && (
            <div className="fixed top-5 right-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg" role="alert">
              <strong className="font-bold">Error: </strong>
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}

          {successMessage && (
            <div className="fixed top-5 right-5 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded shadow-lg" role="alert">
              <strong className="font-bold">Éxito: </strong>
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}

          {/* Formulario de login */}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="numero_cliente" className="block text-sm font-medium text-gray-700">
                Número de Cliente
              </label>
              <input 
                type="text" 
                name="numero_cliente" 
                id="numero_cliente"
                placeholder="Número de cliente" 
                value={numeroCliente}
                onChange={(e) => setNumeroCliente(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mt-1"
                autoComplete="current-numero_cliente"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Contraseña
              </label>
              <input 
                type="password" 
                name="password" 
                id="password"
                placeholder="Contraseña" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mt-1"
                autoComplete="current-password"
              />
            </div>
            <div>
              <button 
                type="submit" 
                className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition"
              >
                Iniciar sesión
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginUsuario;
