import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ReporteMensual = () => {
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [año, setAño] = useState(new Date().getFullYear());
  const [ingresos, setIngresos] = useState(0);
  const [existenciaAnterior, setExistenciaAnterior] = useState(0);
  const [total, setTotal] = useState(0);
  const [saldoFinal, setSaldoFinal] = useState(0);
  const [gastosPorTipo, setGastosPorTipo] = useState({
    'Mantenimiento': 0,
    'Viáticos': 0,
    'Pago Fontanero': 0,
    'Otros': 0,
  });
  const [totalGastos, setTotalGastos] = useState(0);

  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  
  const formatoMoneda = (monto) =>
    new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(monto);

  useEffect(() => {
    const fetchDatos = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/balance-general/${año}`);
        const data = await response.json();

        const ingresoMes = Number(data.ingresosMensuales?.[mes - 1] || 0);
        const egresoMes = Number(data.gastosMensuales?.[mes - 1] || 0);
        const existenciaMesAnterior = Number(
          mes > 1 ? data.saldoMensual?.[mes - 2] || 0 : 0
        );

        setIngresos(ingresoMes);
        setExistenciaAnterior(existenciaMesAnterior);
        setTotal(ingresoMes + existenciaMesAnterior);
        setSaldoFinal(ingresoMes + existenciaMesAnterior - egresoMes);

        const responseGastos = await fetch(
          `http://localhost:8000/api/gastos/historial/mensual/${mes}/${año}`
        );
        const dataGastos = await responseGastos.json();

        const gastosPorTipoTemp = {
          'Mantenimiento': 0,
          'Viáticos': 0,
          'Pago Fontanero': 0,
          'Otros': 0,
        };
        let totalGastosCalculado = 0;

        dataGastos.forEach((gasto) => {
          const tipoGasto = gasto.tipo_gasto?.toLowerCase().trim();
          const monto = Number(gasto.monto) || 0;

          if (tipoGasto === 'mantenimiento') {
            gastosPorTipoTemp['Mantenimiento'] += monto;
          } else if (tipoGasto === 'viáticos' || tipoGasto === 'viático') {
            gastosPorTipoTemp['Viáticos'] += monto;
          } else if (tipoGasto === 'pago fontanero') {
            gastosPorTipoTemp['Pago Fontanero'] += monto;
          } else {
            gastosPorTipoTemp['Otros'] += monto;
          }
          totalGastosCalculado += monto;
        });

        setGastosPorTipo(gastosPorTipoTemp);
        setTotalGastos(totalGastosCalculado);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchDatos();
  }, [mes, año]);

  const generarPDF = async () => {
    try {
      const data = {
        mesNombre: meses[mes - 1],
        año,
        ingresos,
        existenciaAnterior,
        total,
        saldoFinal,
        gastosPorTipo,
        totalGastos,
      };

      const response = await fetch(`http://localhost:8000/api/generar-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Reporte_Mensual_${meses[mes - 1]}_${año}.pdf`;
        link.click();
      } else {
        console.error('Error al generar el PDF:', await response.text());
      }
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold text-center mb-8">
        Reporte Mensual - {meses[mes - 1]} {año}
      </h1>

      {/* Selector de Mes y Año */}
      <div className="flex flex-wrap justify-center gap-4 mb-6">
        <div>
          <label className="font-medium mr-2">Mes:</label>
          <select
            value={mes}
            onChange={(e) => setMes(Number(e.target.value))}
            className="p-2 border rounded"
          >
            {meses.map((mes, index) => (
              <option key={index} value={index + 1}>{mes}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="font-medium mr-2">Año:</label>
          <input
            type="number"
            value={año}
            onChange={(e) => setAño(Number(e.target.value))}
            className="p-2 border rounded w-28"
          />
        </div>
      </div>

      {/* Tabla Resumen */}
      <div className="overflow-x-auto mb-6">
        <table className="w-full border-collapse border border-blue-300 text-sm md:text-base">
          <thead className="bg-gray-200">
            <tr>
              <th className="border p-3">Mes</th>
              <th className="border p-3">Ingresos</th>
              <th className="border p-3">Egresos</th>
              <th className="border p-3">Saldo Final</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border p-3">{meses[mes - 1]}</td>
              <td className="border p-3">{formatoMoneda(ingresos)}</td>
              <td className="border p-3">{formatoMoneda(totalGastos)}</td>
              <td className="border p-3">{formatoMoneda(saldoFinal)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Detalles del Mes */}
      <div className="p-6 bg-white rounded-lg shadow-lg mb-6">
        <h2 className="text-lg font-bold mb-4">Detalles del Mes</h2>
        <p>Entrada del dinero del mes: <b>{formatoMoneda(ingresos)}</b></p>
        <p>Existencia anterior: <b>{formatoMoneda(existenciaAnterior)}</b></p>
        <p>Total: <b>{formatoMoneda(total)}</b></p>
      </div>

      {/* Gastos Generales */}
      <div className="p-6 bg-white rounded-lg shadow-lg mb-6">
        <h2 className="text-lg font-bold mb-4">Gastos Generales</h2>
        {Object.entries(gastosPorTipo).map(([tipo, monto]) => (
          <p key={tipo}>{tipo}: <b>{formatoMoneda(monto)}</b></p>
        ))}
        <p className="font-bold mt-4">Total de gastos: <span className="text-red-600">{formatoMoneda(totalGastos)}</span></p>
        <p className="font-bold">Quedando en caja: <span className="text-green-600">{formatoMoneda(saldoFinal)}</span></p>
      </div>

      {/* Sumas Iguales */}
      <div className="p-6 bg-white-50 rounded-lg shadow-lg">
        <table className="w-full border-collapse border border-blue-400">
          <thead>
            <tr>
              <th className="border p-3 bg-white text-left">SUMAS IGUALES</th>
              <th className="border p-3 text-center font-bold bg-green-500">{formatoMoneda(total)}</th>
              <th className="border p-3 text-center font-bold bg-green-500">{formatoMoneda(totalGastos + saldoFinal)}</th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="text-center mt-6">
        <button
          onClick={generarPDF}
          className="px-6 py-2 bg-blue-500 text-white rounded shadow-lg hover:bg-blue-600"
        >
          Descargar PDF
        </button>
      </div>
    </div>
  );
};

export default ReporteMensual;
