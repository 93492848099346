import React, { useState, useEffect, useCallback } from 'react';

function ListaClientesCorriente() {
  const [clientes, setClientes] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [busqueda, setBusqueda] = useState('');
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);
  const clientesPorPagina = 50;

  // Obtener datos de clientes al corriente
  const fetchListaClientesCorriente = useCallback(async () => {
    setCargando(true);
    setError(null);
    try {
      const response = await fetch('http://localhost:8000/api/clientes/corriente');
      const data = await response.json();

      if (Array.isArray(data)) {
        // Ordenar clientes por nombre completo
        const sortedData = data.sort((a, b) =>
          `${a.nombre} ${a.apellidos}`.localeCompare(`${b.nombre} ${b.apellidos}`)
        );
        setClientes(sortedData);
      } else {
        setClientes([]);
      }
    } catch (error) {
      setError('Hubo un problema al cargar los datos.');
      setClientes([]);
    } finally {
      setCargando(false);
    }
  }, []);

  useEffect(() => {
    fetchListaClientesCorriente();
  }, [fetchListaClientesCorriente]);

  // Filtrar clientes por el término de búsqueda
  const clientesFiltrados = clientes.filter((cliente) =>
    `${cliente.nombre} ${cliente.apellidos}`.toLowerCase().includes(busqueda.toLowerCase())
  );

  // Calcular paginación
  const indexOfLastCliente = paginaActual * clientesPorPagina;
  const indexOfFirstCliente = indexOfLastCliente - clientesPorPagina;
  const clientesPagina = clientesFiltrados.slice(indexOfFirstCliente, indexOfLastCliente);
  const totalPaginas = Math.ceil(clientesFiltrados.length / clientesPorPagina);

  const avanzarPagina = () => {
    if (paginaActual < totalPaginas) {
      setPaginaActual(paginaActual + 1);
    }
  };

  const retrocederPagina = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const handleBusqueda = (e) => {
    setBusqueda(e.target.value);
    setPaginaActual(1);
  };

  if (cargando) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid mx-auto mb-4"></div>
          <p className="text-lg text-gray-700 font-semibold">Cargando datos...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <p className="text-red-500 text-lg font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">Clientes al Corriente</h1>

      {/* Buscador */}
      <div className="mb-4 flex justify-center">
        <input
          type="text"
          placeholder="Buscar cliente por nombre"
          value={busqueda}
          onChange={handleBusqueda}
          className="border p-2 rounded w-full max-w-md shadow-md"
        />
      </div>

      {/* Tabla de clientes */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-4 py-2 text-left">#</th>
              <th className="px-4 py-2 text-left">Nombre completo</th>
              <th className="px-4 py-2 text-left">Referencia</th>
              <th className="px-4 py-2 text-center">Estado</th>
            </tr>
          </thead>
          <tbody>
            {clientesPagina.length > 0 ? (
              clientesPagina.map((cliente, index) => (
                <tr key={cliente.id_cliente} className="border-b">
                  <td className="px-4 py-2">{indexOfFirstCliente + index + 1}</td>
                  <td className="px-4 py-2">{`${cliente.nombre.toUpperCase()} ${cliente.apellidos.toUpperCase()}`}</td>
                  <td className="px-4 py-2">{(cliente.descripcion || '').toUpperCase()}</td>
                  <td className="px-4 py-2 text-center text-green-600 font-semibold">Al corriente</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4 text-gray-500">
                  No hay clientes al corriente.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Paginación */}
      {totalPaginas > 1 && (
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={retrocederPagina}
            disabled={paginaActual === 1}
            className={`px-4 py-2 rounded-lg ${
              paginaActual === 1
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {paginaActual} de {totalPaginas}
          </span>
          <button
            onClick={avanzarPagina}
            disabled={paginaActual === totalPaginas}
            className={`px-4 py-2 rounded-lg ${
              paginaActual === totalPaginas
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Siguiente
          </button>
        </div>
      )}
    </div>
  );
}

export default ListaClientesCorriente;

