import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';

function GestionLogos() {
  const [logos, setLogos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedLogoId, setSelectedLogoId] = useState(null);
  const [loadingLogos, setLoadingLogos] = useState(false);
  const [actionLoading, setActionLoading] = useState(null);

  const fileInputRef = useRef(null);

  const fetchLogos = async () => {
    setLoadingLogos(true);
    try {
      const logosResponse = await axios.get('http://localhost:8000/api/logos');
      setLogos(logosResponse.data);

      const activeLogoResponse = await axios.get('http://localhost:8000/api/logos/active');
      if (activeLogoResponse.data?.id_logo) {
        setSelectedLogoId(activeLogoResponse.data.id_logo);
      }
    } catch (error) {
    
    } finally {
      setLoadingLogos(false);
    }
  };

  useEffect(() => {
    fetchLogos();
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    const formData = new FormData();
    formData.append('logo', selectedFile);

    try {
      setIsUploading(true);
      const response = await axios.post('http://localhost:8000/api/logos', formData);
      Swal.fire('Éxito', response.data.success, 'success');
      setSelectedFile(null);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      fetchLogos();
    } catch (error) {
      Swal.fire('Error', 'No se pudo subir el logo.', 'error');
     
    } finally {
      setIsUploading(false);
    }
  };

  const handleSelect = async (id_logo) => {
    try {
      setActionLoading(id_logo);
      const response = await axios.put(`http://localhost:8000/api/logos/${id_logo}/activate`);
      Swal.fire('Logo seleccionado', response.data.success, 'success');
      setSelectedLogoId(id_logo);
    } catch (error) {
      Swal.fire('Error', 'No se pudo activar el logo.', 'error');
    
    } finally {
      setActionLoading(null);
    }
  };

  const handleDelete = async (id_logo) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setActionLoading(id_logo);
          const response = await axios.delete(`http://localhost:8000/api/logos/${id_logo}`);
          Swal.fire('Eliminado', response.data.success, 'success');
          fetchLogos();
        } catch (error) {
          Swal.fire('Error', 'No se pudo eliminar el logo.', 'error');
       
        } finally {
          setActionLoading(null);
        }
      }
    });
  };

  return (
    <div className="w-full p-4 md:p-6">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center">Gestión de Logos</h1>

      <div className="flex flex-col md:flex-row items-center justify-center mb-6 space-y-4 md:space-y-0 md:space-x-4">
        <input
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          className="bg-white border p-2 rounded w-full md:w-auto"
        />
        <button
          onClick={handleUpload}
          className="w-full md:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center justify-center"
          disabled={isUploading || !selectedFile}
        >
          {isUploading ? <ClipLoader color="#fff" size={20} /> : 'Subir Logo'}
        </button>
      </div>

      {loadingLogos ? (
        <div className="flex flex-col justify-center items-center h-48">
          <ClipLoader color="#3b82f6" size={50} />
          <p className="mt-4 text-blue-500 text-lg font-semibold">Cargando logos...</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {logos.map((logo) => (
            <div
              key={logo.id_logo}
              className={`p-4 rounded-lg shadow-md bg-white w-full ${
                selectedLogoId === logo.id_logo ? 'border-4 border-green-500' : 'border'
              }`}
            >
              <img
                src={`http://localhost:8000/logos/${logo.path}`}
                alt="Logo"
                className="w-full h-32 object-contain mb-4 bg-gray-50"
              />
              <div className="flex justify-between">
                {selectedLogoId !== logo.id_logo && (
                  <button
                    onClick={() => handleSelect(logo.id_logo)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full md:w-auto mr-2"
                    disabled={actionLoading === logo.id_logo}
                  >
                    {actionLoading === logo.id_logo ? (
                      <ClipLoader color="#fff" size={20} />
                    ) : (
                      'Seleccionar'
                    )}
                  </button>
                )}
                <button
                  onClick={() => handleDelete(logo.id_logo)}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 w-full md:w-auto"
                  disabled={actionLoading === logo.id_logo}
                >
                  {actionLoading === logo.id_logo ? (
                    <ClipLoader color="#fff" size={20} />
                  ) : (
                    'Eliminar'
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GestionLogos;
