import React, { useState } from 'react';
import {
  HomeIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Importar el contexto de autenticación

const SidebarCliente = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth(); // Obtener el método logout del contexto

  const handleLogout = () => {
    logout(); // Llamar a logout para limpiar los datos de sesión
    navigate('/login/usuarios'); // Redirigir a la página de inicio de sesión
  };

  const handleMenuClick = (path) => {
    navigate(path); // Navegar a la ruta
    setIsOpen(false); // Cerrar el menú
  };

  return (
    <>
      {/* Botón de menú para pantallas pequeñas */}
      <button
        className={`lg:hidden p-4 text-white bg-blue-500 fixed top-0 left-0 z-50 rounded-full shadow-lg ${
          isOpen ? 'hidden' : 'block'
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bars3Icon className="h-6 w-6" />
      </button>

      {/* Overlay para el fondo oscuro */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`h-screen w-64 bg-gray-800 text-white transform lg:transform-none transition-transform duration-300 ease-in-out fixed lg:relative z-50 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center p-4">
          <h2 className="text-2xl font-bold">Panel del Cliente</h2>
          <button
            className="text-white lg:hidden"
            onClick={() => setIsOpen(false)}
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <ul className="space-y-4 px-4">
          {/* Inicio */}
          <li
            className="hover:bg-gray-700 p-2 rounded flex items-center cursor-pointer"
            onClick={() => handleMenuClick('/dashboard/usuario')}
          >
            <HomeIcon className="h-6 w-6 mr-2" />
            <span className="block text-white">Inicio</span>
          </li>

          {/* Historial de Pagos */}
          <li
            className="hover:bg-gray-700 p-2 rounded flex items-center cursor-pointer"
            onClick={() => handleMenuClick('/historial-pagos')}
          >
            <UserIcon className="h-6 w-6 mr-2" />
            <span className="block text-white">Historial de Pagos</span>
          </li>

          {/* Cerrar Sesión */}
          <li
            className="hover:bg-gray-700 p-2 rounded flex items-center cursor-pointer"
            onClick={handleLogout}
          >
            <ArrowLeftOnRectangleIcon className="h-6 w-6 mr-2" />
            <span className="block text-white">Cerrar Sesión</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarCliente;
