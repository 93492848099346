import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './componentes/context/AuthContext';
import ProtectedRoute from './componentes/ProtectedRoute';
import Sidebar from './componentes/Sidebar';
import SidebarCliente from './componentes/usuario/SidebarCliente';
import RegistrarCliente from './componentes/RegistrarCliente';
import ListaClientes from './componentes/ListaClientes';
import RegistrarPago from './componentes/pagos/RegistrarPago';
import Inicio from './componentes/Inicio';
import RegistrarPagoCliente from './componentes/pagos/RegistrarPagoCliente';
import ListaClientesMora from './componentes/ListaClientesMora';
import ListaClientesCorriente from './componentes/ListaClientesCorriente';
import HistorialPagos from './componentes/pagos/HistorialPagos';
import RegistrarGasto from './componentes/gastos/RegistrarGasto';
import HistorialGastos from './componentes/gastos/HistorialGastos';
import ConfiguracionUsuario from './componentes/config/ConfiguracionUsuario';
import ManualDeUsuario from './componentes/config/ManualDeUsuario';
import GraficasGastos from './componentes/gastos/GraficasGastos';
import LoginUsuario from './componentes/auth/LoginUsuario';
import LoginPersonal from './componentes/auth/LoginPersonal';
import BienvenidoCliente from './componentes/usuario/BienvenidoCliente';
import GestionLogos from './componentes/logos/GestionLogos';
import ListaClientesAusentes from './componentes/ListaClientesAusentes';
import BalanceGeneral from './componentes/Reportes/BalanceGeneral';
import HistorialPagoC from './componentes/usuario/HistorialPagoC';
import ReporteMensual from './componentes/Reportes/ReporteMensual';
// Componente AuthLayout para la página de login
function AuthLayout({ children }) {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      {children}
    </div>
  );
}

// Componente DashboardLayout para mostrar Sidebar dinámicamente
function DashboardLayout({ children }) {
  const { userType } = useAuth();
  return (
    <div className="flex h-screen overflow-hidden">
      {userType === 'personal' ? <Sidebar /> : <SidebarCliente />}
      <div className="flex-1 overflow-auto p-6 bg-gray-100">
        {children}
      </div>
    </div>
  );
}


// Configuración de rutas de la aplicación
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Redirige de "/" a "/login/usuarios" */}
          <Route path="/" element={<Navigate to="/login/usuarios" />} />

          {/* Ruta para login de usuarios */}
          <Route
            path="/login/usuarios"
            element={
              <AuthLayout>
                <LoginUsuario />
              </AuthLayout>
            }
          />

          {/* Ruta para login de personal */}
          <Route
            path="/login/personal"
            element={
              <AuthLayout>
                <LoginPersonal />
              </AuthLayout>
            }
          />

          {/* Ruta del Dashboard general */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Inicio />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

           {/* Ruta del Dashboard general */}
           <Route
            path="/dashboard/usuario"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <BienvenidoCliente
                   />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

           {/* Ruta del Dashboard general */}
           <Route
            path="/historial-pagos"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <HistorialPagoC
                   />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Rutas para Clientes */}
          <Route
            path="/clientes/registrar"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <RegistrarCliente />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes/lista"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListaClientes />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes/mora"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListaClientesMora />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/clientes/corriente"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListaClientesCorriente />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Rutas para Pagos */}
          <Route
            path="/pagos/registrar"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <RegistrarPago />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/pagos/registrar/:id_cliente"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <RegistrarPagoCliente />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/pagos/historial"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <HistorialPagos />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Rutas para Gastos */}
          <Route
            path="/gastos/registrar"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <RegistrarGasto />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/gastos/historial"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <HistorialGastos />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/gastos/graficas"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GraficasGastos />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Rutas para Configuración */}
          <Route
            path="/configuracion/usuarios"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ConfiguracionUsuario />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/configuracion/logo"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GestionLogos />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Ruta de Soporte */}
          <Route
            path="/soporte/manual"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ManualDeUsuario />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
           <Route
            path="/clientes/ausentes"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ListaClientesAusentes />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/reportes/ingresos-y-gastos"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <BalanceGeneral />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/reporte/mensual"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ReporteMensual />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
      </Routes>
      
      </Router>
    </AuthProvider>
  );
}

export default App;
