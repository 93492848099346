import React from 'react';

const ManualDeUsuario = () => {
  return (
    <div className="p-4 md:p-6 lg:p-8 bg-white shadow-lg rounded-lg max-w-full w-full mx-auto">
      <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center">Manual de Usuario</h1>
      <button
  className="mb-6 px-4 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 float-right mr-2"
  onClick={() => window.open('http://localhost:8000/manual/manual_operativo.pdf', '_blank')}
>
  Ver el Manual
</button>



      {/* Introducción */}
      <section className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Introducción</h2>
        <p className="text-justify text-base md:text-lg">
          Bienvenido al sistema de gestión de agua potable. Este manual está diseñado para guiarte en el uso
          eficiente de todas las funciones del sistema, desde la gestión de clientes hasta la generación de reportes financieros.
        </p>
      </section>

      {/* Acceso al Sistema */}
      <section className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Acceso al Sistema</h2>
        <p className="text-justify text-base md:text-lg">
          Para acceder al sistema, utiliza tu nombre de usuario y contraseña. Si no tienes una cuenta, contacta al administrador para crear una.
        </p>
        <ol className="list-decimal pl-6 text-base md:text-lg">
          <li>Dirígete a la página de inicio de sesión.</li>
          <li>Introduce tu nombre de usuario y contraseña.</li>
          <li>Haz clic en el botón de "Iniciar Sesión".</li>
        </ol>
      </section>

      {/* Gestión de Clientes */}
      <section className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Gestión de Clientes</h2>
        <p className="text-justify text-base md:text-lg">
          En la sección de "Clientes", puedes agregar, editar y eliminar clientes. Para gestionar clientes, sigue estos pasos:
        </p>
        <ol className="list-decimal pl-6 text-base md:text-lg">
          <li>Accede a la sección de "Clientes".</li>
          <li>Haz clic en "Agregar Cliente" para registrar un nuevo cliente.</li>
          <li>Completa los datos requeridos y guarda la información.</li>
          <li>Para editar o eliminar un cliente, selecciona el cliente en la lista y usa las opciones disponibles.</li>
        </ol>
      </section>

      {/* Gestión de Pagos */}
      <section className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Gestión de Pagos</h2>
        <p className="text-justify text-base md:text-lg">
          El sistema te permite registrar pagos para los clientes. Puedes seleccionar los meses que desees pagar y realizar un seguimiento del historial de pagos.
        </p>
        <ol className="list-decimal pl-6 text-base md:text-lg">
          <li>Accede a la sección de "Pagos".</li>
          <li>Selecciona el cliente para el que deseas registrar un pago.</li>
          <li>Selecciona los meses a pagar y confirma el pago.</li>
          <li>Verifica el historial de pagos en la misma sección.</li>
        </ol>

       
      </section>

      {/* Gestión de Gastos */}
      <section className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Gestión de Gastos</h2>
        <p className="text-justify text-base md:text-lg">
          El sistema permite registrar gastos operativos, como el pago al fontanero, mantenimiento de equipos y viáticos. Sigue estos pasos para registrar un gasto:
        </p>
        <ol className="list-decimal pl-6 text-base md:text-lg">
          <li>Accede a la sección de "Gastos".</li>
          <li>Haz clic en "Registrar Gasto".</li>
          <li>Completa los campos requeridos, como descripción del gasto, monto y categoría.</li>
          <li>Guarda la información para que quede registrada en el sistema.</li>
        </ol>

        
      </section>

      {/* Otros apartados... */}
      <section className="mb-8">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Seguridad y Actualización Automática</h2>
        <p className="text-justify text-base md:text-lg">
          El sistema está diseñado con actualizaciones automáticas para la información del logo y los balances cada 30 segundos, asegurando que los datos reflejen cualquier cambio reciente en tiempo real. Para mantener el sistema seguro, todas las contraseñas están encriptadas.
        </p>
      </section>

      <section className="mb-8">
  <h2 className="text-2xl md:text-3xl font-semibold mb-4">Hosting del Sistema</h2>
  <p className="text-justify text-base md:text-lg">
    El sistema web <strong>ServiAguaBoquilla</strong> está alojado en el proveedor de servicios de hosting <strong>Hostinger</strong>. El plan contratado incluye características como:
  </p>
  <ul className="list-disc pl-5 text-justify text-base md:text-lg">
    <li>Almacenamiento SSD de 100 GB</li>
    <li>Soporte para PHP y MySQL</li>
    <li>Certificado SSL gratuito</li>
    <li>Acceso a cPanel para la gestión del hosting</li>
    <li>Dominio personalizado</li>
  </ul>
  <p className="text-justify text-base md:text-lg">
    La renovación del hosting está programada para el <strong>01 de diciembre de 2025</strong>. Es importante realizar el pago antes de esta fecha para evitar interrupciones en el servicio.
  </p>
  <ul>
  <li>Para ello contacte con alguno de los residentes del proyecto. Tel. 9541137271</li>
  </ul>
</section>
<footer className="text-center text-sm md:text-base mt-8">
  <p>Todos los derechos reservados © 2024. ServiAguaBoquilla.</p>
</footer>

    </div>
  );
};

export default ManualDeUsuario;
