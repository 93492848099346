// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);
  const [clienteInfo, setClienteInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedAuth = sessionStorage.getItem('isAuthenticated') === 'true';
    const savedUserType = sessionStorage.getItem('userType');
    const clienteInfoData = sessionStorage.getItem('clienteInfo');

    let savedClienteInfo = null;
    if (savedUserType === 'usuario' && clienteInfoData) {
        try {
            savedClienteInfo = JSON.parse(clienteInfoData);
        } catch (error) {
            sessionStorage.removeItem('clienteInfo');
        }
    }

    if (savedAuth && savedUserType) {
        setIsAuthenticated(savedAuth);
        setUserType(savedUserType);
        setClienteInfo(savedClienteInfo);
    }
    setLoading(false);
}, []);

const login = (type, userData) => {
  setIsAuthenticated(true);
  setUserType(type);
  setClienteInfo(userData);

  sessionStorage.setItem('isAuthenticated', 'true');
  sessionStorage.setItem('userType', type);
  sessionStorage.setItem('clienteInfo', JSON.stringify(userData));
};

const logout = () => {
  setIsAuthenticated(false);
  setUserType(null);
  setClienteInfo(null);

  sessionStorage.removeItem('isAuthenticated');
  sessionStorage.removeItem('userType');
  sessionStorage.removeItem('clienteInfo');
};

useEffect(() => {
  const savedAuth = sessionStorage.getItem('isAuthenticated') === 'true';
  const savedUserType = sessionStorage.getItem('userType');
  const clienteInfoData = sessionStorage.getItem('clienteInfo');

  let savedClienteInfo = null;
  if (savedUserType === 'usuario' && clienteInfoData) {
    try {
      savedClienteInfo = JSON.parse(clienteInfoData);
    } catch (error) {
      sessionStorage.removeItem('clienteInfo');
    }
  }

  if (savedAuth && savedUserType) {
    setIsAuthenticated(savedAuth);
    setUserType(savedUserType);
    setClienteInfo(savedClienteInfo);
  } else {
    setIsAuthenticated(false); // Asegúrate de desactivar la autenticación si no hay datos válidos
  }

  setLoading(false);
}, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userType, clienteInfo, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
