import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const ListaClientesAusentes = () => {
  const [clientesAusentes, setClientesAusentes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [mostrarModal, setMostrarModal] = useState(false);

  const navigate = useNavigate(); // Hook para redirigir
  const clientesPerPage = 50;

  useEffect(() => {
    fetchClientesAusentesList();
  }, [currentPage]);

  const fetchClientesAusentesList = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`http://localhost:8000/api/clientes/ausentes/listar?page=${currentPage}&limit=${clientesPerPage}`);
      const data = await response.json();
      setClientesAusentes(data.clientes_ausentes || []);
    } catch (error) {
    }
    setIsLoading(false);
  };

  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const handleReactivar = (cliente) => {
    setClienteSeleccionado(cliente);
    setMostrarModal(true); // Mostrar la ventana modal
  };

  const confirmarReactivacion = async (opcionMes) => {
    if (!clienteSeleccionado) return;

    const id_cliente = clienteSeleccionado.id_cliente;
    const fechaActual = new Date();
    const mesInicio = opcionMes === 'actual' ? fechaActual.getMonth() + 1 : fechaActual.getMonth() + 2;
    const añoInicio = fechaActual.getFullYear();

    try {
      const response = await fetch(`http://localhost:8000/api/pagos/reactivar/${id_cliente}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          mes_inicio: mesInicio,
          año_inicio: añoInicio,
          fecha_pago: new Date().toISOString().split('T')[0],
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Error al registrar la reactivación');
      }

      Swal.fire('Éxito', `Reactivado en el mes de ${meses[mesInicio - 1]} ${añoInicio}`, 'success');
      setMostrarModal(false);
      setClienteSeleccionado(null);
      fetchClientesAusentesList(); // Refrescar la lista

      // Redirigir al formulario de pagos
      navigate(`/pagos/registrar/${id_cliente}`);
    } catch (error) {
      Swal.fire('Error', 'No se pudo registrar la reactivación', 'error');
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      <h2 className="text-2xl text-center font-bold mb-4">Lista de Clientes Ausentes</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-4 py-2 text-left">#</th>
              <th className="px-4 py-2 text-left">Nombre completo</th>
              <th className="px-4 py-2 text-left">Referencia</th>
              <th className="px-4 py-2 text-left">Mes</th>
              <th className="px-4 py-2 text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="5" className="text-center py-4">Cargando datos...</td>
              </tr>
            ) : clientesAusentes.length > 0 ? (
              clientesAusentes.map((cliente, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{(currentPage - 1) * clientesPerPage + index + 1}</td>
                  <td className="px-4 py-2 whitespace-nowrap">{cliente.nombre.toUpperCase()} {cliente.apellidos.toUpperCase()}</td>
                  <td className="px-4 py-2 whitespace-nowrap">{cliente.descripcion || ''}</td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {meses[cliente.mes_inicio - 1] || 'Mes no válido'} {cliente.año_inicio}
                  </td>
                  <td className="px-4 py-2">
                    {cliente.id_cliente ? (
                      <button
                        onClick={() => handleReactivar(cliente)}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                      >
                        Reactivar
                      </button>
                    ) : (
                      <span className="text-red-500">ID no disponible</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">No hay clientes ausentes registrados.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
        >
          Anterior
        </button>
        <button
          onClick={handleNextPage}
          disabled={clientesAusentes.length < clientesPerPage}
          className={`px-4 py-2 rounded-lg ${clientesAusentes.length < clientesPerPage ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
        >
          Siguiente
        </button>
      </div>

      {/* Modal para Reactivación */}
      {mostrarModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-xl font-bold mb-4">Reactivar Cliente</h3>
            <p className="mb-4">
              ¿En qué mes deseas registrar la reactivación?
            </p>
            <div className="flex space-x-4 mb-4">
              <button
                onClick={() => confirmarReactivacion('actual')}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                {`Mes Actual (${meses[new Date().getMonth()]})`}
              </button>
              <button
                onClick={() => confirmarReactivacion('siguiente')}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                {`Próximo Mes (${meses[(new Date().getMonth() + 1) % 12]})`}
              </button>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setMostrarModal(false)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListaClientesAusentes;
