import React, { useState } from 'react';
import Swal from 'sweetalert2';

function RegistrarCliente() {
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [reactivacion, setReactivacion] = useState(false);
  const [numeroCliente, setNumeroCliente] = useState('');
  const [passwordGenerada, setPasswordGenerada] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const clienteData = {
      nombre,
      apellidos,
      descripcion,
      reactivacion,
      fecha_reactivacion: reactivacion ? new Date().toISOString().split('T')[0] : null, // Registrar la fecha de reactivación
    };

    try {
      const response = await fetch('http://localhost:8000/api/clientes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clienteData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setNumeroCliente(result.cliente.numero_cliente);
      setPasswordGenerada(result.password_generada);

      Swal.fire({
        icon: 'success',
        title: 'Cliente registrado exitosamente',
        html: `<p><strong>Número de Cliente:</strong> ${result.cliente.numero_cliente}</p>
               <p><strong>Contraseña Generada:</strong> ${result.password_generada}</p>`,
      });

      setNombre('');
      setApellidos('');
      setDescripcion('');
      setReactivacion(false);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Hubo un problema con la solicitud: ${error.message}`,
      });
    }
  };

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Formulario de cliente al lado izquierdo de la imagen */}
      <div className="w-full lg:w-2/3 p-4">
        <h2 className="text-2xl font-bold mb-4 text-center w-full">Registrar Cliente</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Formulario de cliente */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Nombre:</label>
            <input
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
              className="border p-2 w-full rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-800">Apellidos:</label>
            <input
              type="text"
              value={apellidos}
              onChange={(e) => setApellidos(e.target.value)}
              required
              className="border p-2 w-full rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-800">Descripción (Opcional):</label>
            <input
              type="text"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              className="border p-2 w-full rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-800">
              <input
                type="checkbox"
                checked={reactivacion}
                onChange={() => setReactivacion(!reactivacion)}
              />
              Reactivación de servicio (Cobro de $200)
            </label>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg w-full lg:w-auto"
          >
            Registrar Cliente
          </button>
        </form>
      </div>
      {/* Imagen a la derecha */}
      <div className="hidden lg:flex flex-shrink-0 ml-8 mt-20">
        <img src="/img/Seguidores.png" alt="Gasto" className="w-90 h-85 object-contain" />
      </div>
    </div>
  );
}

export default RegistrarCliente;
